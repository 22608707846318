body {
    margin: 0;
    overflow-x: hidden;
}

/*.large {*/
/*    display: none;*/
/*}*/


/*@media only screen and (min-width: 600px) {*/
/*    .large {*/
/*      display: inline-block;*/
/*    }*/
/*    .small {*/
/*      display: none;*/
/*    }*/
/*}*/